var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":"Feature-Branch Setup"}},[_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","hint":"Propriedade: db_name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.$apollo.queries.accountList.loading,"item-key":"db_name","items":_vm.accounts,"search":_vm.search,"headers":_vm.headers,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"item.db_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.db_name)+" ")]}},{key:"item.dag.tasks_branches",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!item.dag.tasks_branches || !Object.keys(item.dag.tasks_branches).length ? '-' : Object.keys(item.dag.tasks_branches).length)+" ")]}},{key:"item.status_account",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapStatusAccount(item.status_account))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.active ? 'success' : 'error'}},[_vm._v("mdi-brightness-1")]),_vm._v(" "+_vm._s(item.active ? 'Ativo' : 'Inativo')+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.taskDetails(item._id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-cog")]),_vm._v(" Configurar Tasks")],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),(_vm.accountId)?_c('tasks-details',{attrs:{"dialog":_vm.taskDetailsDialog,"account-id":_vm.accountId},on:{"close":_vm.close}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }