<template>
  <base-page-layout title="Feature-Branch Setup">
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field v-model="search" append-icon="search" label="Buscar" hint="Propriedade: db_name" />
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selected"
          :loading="$apollo.queries.accountList.loading"
          item-key="db_name"
          :items="accounts"
          :search="search"
          :headers="headers"
          :custom-sort="customSort"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.db_name="{ item }">
            {{ item.db_name }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.dag.tasks_branches="{ item }">
            {{ !item.dag.tasks_branches || !Object.keys(item.dag.tasks_branches).length ? '-' : Object.keys(item.dag.tasks_branches).length }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.status_account="{ item }">
            {{ mapStatusAccount(item.status_account) }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.active="{ item }">
            <v-icon class="mr-2" x-small :color="item.active ? 'success' : 'error'">mdi-brightness-1</v-icon> {{ item.active ? 'Ativo' : 'Inativo' }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.details="{ item }">
            <v-btn color="primary" small outlined @click="taskDetails(item._id)"><v-icon class="mr-2" small>mdi-cog</v-icon> Configurar Tasks</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <tasks-details v-if="accountId" :dialog="taskDetailsDialog" :account-id="accountId" @close="close" />
  </base-page-layout>
</template>

<script>
import { QUERY_ACCOUNT_FB_LIST } from '@/modules/feature_branch/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout'),
    TasksDetails: () => import('@/modules/feature_branch/components/TasksDetails')
  },
  data: () => ({
    accounts: [],
    selected: [],
    taskDetailsDialog: false,
    search: '',
    accountId: ''
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Database',
          value: 'db_name',
          width: '20%'
        },
        {
          text: 'Tasks Configuradas',
          value: 'dag.tasks_branches',
          width: '20%'
        },
        {
          text: 'Status',
          value: 'status_account',
          width: '20%',
          sortable: false
        },
        {
          text: 'Cliente',
          value: 'active',
          width: '20%'
        },
        {
          text: '',
          value: 'details',
          width: '20%',
          sortable: false
        }
      ]
    }
  },
  apollo: {
    accountList: {
      query: QUERY_ACCOUNT_FB_LIST,
      fetchPolicy: 'network-only',
      update({ accountList }) {
        this.accounts = accountList
      }
    }
  },
  methods: {
    mapStatusAccount(status_account) {
      if (status_account?.deploying) return 'Implantando'
      if (status_account?.done) return 'Concluído'
      if (status_account?.churn) return 'Churn'
      if (status_account?.impossible_support) return 'Suporte impossível'
      if (status_account?.test) return 'Teste'
      if (status_account?.temporary_churn) return 'Churn temporário'
      if (status_account?.adjusting) return 'Em ajuste'
      if (status_account?.deprecated) return 'Plataforma Antiga'
      else return 'Sem Status'
    },
    close() {
      this.dialog = false
      this.taskDetailsDialog = false
      this.selected = []
      this.selectedAccounts = []
      this.selectedAccount = {}
      this.$apollo.queries.accountList.refresh()
    },
    taskDetails(id) {
      this.accountId = id
      this.taskDetailsDialog = true
    },
    customSort(items, index, isDesc) {
      if (!index[0] || !index.length || !isDesc || !isDesc.length) return items
      if (index[0] === 'dag.tasks_branches') {
        if (!isDesc[0]) {
          items.sort((a, b) => {
            return Object.keys(a.dag?.tasks_branches || {}).length > Object.keys(b.dag?.tasks_branches || {}).length ? 1 : -1
          })
        } else {
          items.sort((a, b) => {
            return Object.keys(a.dag?.tasks_branches || {}).length < Object.keys(b.dag?.tasks_branches || {}).length ? 1 : -1
          })
        }
      }

      if (index[0] === 'db_name') {
        if (!isDesc[0]) {
          items.sort((a, b) => {
            return a.db_name > b.db_name ? 1 : -1
          })
        } else {
          items.sort((a, b) => {
            return a.db_name < b.db_name ? 1 : -1
          })
        }
      }

      if (index[0] === 'active') {
        if (!isDesc[0]) {
          items.sort((a, b) => {
            return a.active > b.active ? 1 : -1
          })
        } else {
          items.sort((a, b) => {
            return a.active < b.active ? 1 : -1
          })
        }
      }

      return items
    }
  }
}
</script>
