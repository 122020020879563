import gql from 'graphql-tag'

export const QUERY_ACCOUNT_FB_LIST = gql`
  query {
    accountList {
      _id
      db_name
      dag {
        tasks_branches
      }
      active
      status_account {
        deploying
        done
        impossible_support
        test
        churn
        temporary_churn
        adjusting
        deprecated
      }
    }
  }
`
export const QUERY_ACCOUNT_FEATURE_BRANCH = gql`
  query($id: ID!) {
    accountFeatureBranch(id: $id) {
      _id
      db_name
      dag {
        tasks_branches
      }
      active
      status_account {
        deploying
        done
        impossible_support
        test
        churn
        temporary_churn
        adjusting
        deprecated
      }
    }
  }
`
export const MUTATION_TRIGGER_SET_TASKS = gql`
  mutation($tasks: [String!], $feature: String!, $db_names: [String!]) {
    triggerActionFB(tasks: $tasks, feature: $feature, db_names: $db_names)
  }
`
export const MUTATION_CLEAN_TASKS_BRANCHES = gql`
  mutation($db_names: [String!]) {
    cleanTasks(db_names: $db_names)
  }
`
export const MUTATION_UPDATE_TASKS_BRANCHES = gql`
  mutation($db_name: String!, $tasks_branches: JSONObject!) {
    updateTasks(db_name: $db_name, tasks_branches: $tasks_branches)
  }
`
export const MUTATION_REMOVE_TASK = gql`
  mutation($db_name: String!, $task_key: String!) {
    removeTask(db_name: $db_name, task_key: $task_key)
  }
`
export const MUTATION_ADD_NEW_TASK = gql`
  mutation($db_name: String!, $tasks: [String!], $feature: String!) {
    addNewTasks(db_name: $db_name, tasks: $tasks, feature: $feature)
  }
`
